import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import dayjs from "dayjs";
import PortableText from "react-portable-text";

const BlogCard = ({ item }) => {
  return (
    <div className="block group">
      <Link
        to={`/blog/${item.slug.current}`}
        className="block relative no-underline"
      >
        {/* Image Container */}
        <div className="relative bg-[#e4e8ed] block">
          <div className="block w-full h-full z-[1] object-cover aspect-video">
            {item?.mainImage && (
              <GatsbyImage
                image={item?.mainImage?.asset?.gatsbyImageData}
                alt={item.mainImage?.alt}
                className="w-full h-full object-cover object-center group-hover:scale-[1.02] transition-all duration-200 ease-in-out"
              />
            )}
          </div>
          {/* <div className="absolute top-2 left-2 z-[2] bg-[#121923] text-white px-2 uppercase">
            <span className="text-[15px] font-bold tracking-wide">
              Blog Post
            </span>
          </div> */}
        </div>
        {/* Heading */}
        <h2 className="mb-[6px] mt-5 text-[#121923] font-bold text-xl">
          {item.title}
        </h2>
        {/* Description */}
        <div className="block text-[#3C4758] text-xs sm:text-base">
          {item?._rawDescription && (
            <PortableText
              content={item?._rawDescription}
              className="font-semibold text-[#323A44] block-content max-w-none"
              serializers={{
                link: () => {
                  return <></>;
                },
              }}
            />
          )}
        </div>
        {/* Category */}
        {item?.category?.length > 0 && (
          <div className="font-primary mt-4 text-xs uppercase font-bold">
            Category:{" "}
            <span className="font-poppins">{item?.category[0].title}</span>
          </div>
        )}
        {/* Published Date */}
        <div className="mt-3 text-[#515E72] text-[13px] md:text-[15px] font-medium">
          {dayjs(item?.publishedAt).format("DD-MM-YYYY")}
        </div>
      </Link>
    </div>
  );
};

export default BlogCard;
