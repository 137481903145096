import React from "react";
import { graphql } from "gatsby";
import BlogCard from "../components/common/blogcard";
import Layout from "../components/layouts";

const Blog = ({ data: { allSanityPost } }) => {
  return (
    <Layout navBg="black">
      <div className="max-w-[1344px] w-full h-full mx-auto px-6 md:px-12 py-[96px] box-border">
        <h1 className="mb-5 font-bold text-4xl uppercase text-assent">
          Blog POSTS
        </h1>
        <hr />
        <div className="grid w-full h-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[25px] mt-5">
          {allSanityPost?.edges.map((item, index) => (
            <BlogCard item={item.node} key={index} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
export const query = graphql`
  query {
    allSanityPost(sort: { order: DESC, fields: publishedAt }) {
      edges {
        node {
          id
          publishedAt
          title
          _rawDescription
          category {
            title
          }
          slug {
            current
          }
          mainImage {
            alt
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
    }
  }
`;
